import tinymce from "tinymce/tinymce";

function main() {
	tinymce.init({
		selector: "#mytextarea",
		license_key: "gpl|<your-license-key>",
		plugins: "lists",
		base_url: "/static/dist/tinymce",
	});
}

export default main;
