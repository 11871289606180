function main() {
	const navbarBurgers = document.querySelectorAll(".navbar-burger");

	for (const el of navbarBurgers) {
		el.addEventListener("click", () => {
			const target = el.dataset.target;
			const $target = document.getElementById(target);

			el.classList.toggle("is-active");
			$target.classList.toggle("is-active");
		});
	}
}

export default main;
