function main() {
	const notifications = document.querySelectorAll(".notification .delete");
	for (const notification of notifications) {
		notification.addEventListener("click", () => {
			notification.parentNode.remove();
		});
	}
}

export default main;
