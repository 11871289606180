function openModal(target) {
	target.classList.add("is-active", "animate__animated", "animate__fadeIn");
}

function closeModal(target) {
	target.classList.add("animate__animated", "animate__fadeOut");

	target.addEventListener("animationend", function handleAnimationEnd() {
		target.classList.remove(
			"is-active",
			"animate__animated",
			"animate__fadeOut",
		);
		target.removeEventListener("animationend", handleAnimationEnd);
	});
}

function main() {
	const modalTriggers = document.querySelectorAll(".js-modal-trigger");

	for (const trigger of modalTriggers) {
		const modal = trigger.dataset.target;
		const target = document.getElementById(modal);

		trigger.addEventListener("click", () => {
			openModal(target);
		});
	}

	const modals = document.querySelectorAll(".modal button");
	for (const modal of modals) {
		modal.addEventListener("click", (event) => {
			if (event.target === modal) {
				closeModal(modal);
			}
		});
	}

	// close the modal when the escape key is pressed
	document.addEventListener("keydown", (event) => {
		if (event.key === "Escape") {
			const activeModal = document.querySelector(".modal.is-active");
			if (activeModal) {
				closeModal(activeModal);
			}
		}
	});

	// Add a click event on various child elements to close the parent modal
	const modal_child_elements = document.querySelectorAll(
		".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button",
	);
	for (const child of modal_child_elements) {
		child.addEventListener("click", () => {
			const target = child.closest(".modal");
			closeModal(target);
		});
	}
}

export default main;
